<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img col="0.5" :src="appLogoImage" />
        <h2 class="brand-text text-primary ml-1">Medloop</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <!--  <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          /> -->
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Welcome to Medloop! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- forgot password -->

          <!-- checkbox -->
          <input type="image" :src="msSignInImage" @click="login()" />

          <!-- submit buttons -->

          <!-- divider -->
          <div class="divider my-2" />

          <!-- social buttons -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
// import useJwt from '../auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      msSignInImage: require("@/assets/images/logo/ms_signin_dark.png"),
      appLogoImage: require("/public/logoMini.png"),
    };
  },
  methods: {
    login() {
      const { origin } = new URL(document.URL);
      location.href = `https://login.microsoftonline.com/medloopus.com/oauth2/v2.0/authorize?client_id=019db20d-1c27-4dff-8079-3d6a6a90f0d8&scope=user.read openid profile offline_access api://2939e60e-f743-4323-8006-a767aa11ee79/access_as_user&redirect_uri=${origin}/loginRedirect&response_mode=query&response_type=code&sso_reload=true`;
      // location.href=`https://medloopusauth.b2clogin.com/medloopusauth.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SIgnUp_In&client_id=91b921d4-dd4f-4646-a57a-6e9d2c3cf2a6&nonce=defaultNonce&redirect_uri=${origin}/loginRedirect&scope=openid profile offline_access&response_type=code&prompt=login`
   },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
